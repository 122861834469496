import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import marked from "marked"
import cx from "classnames"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Resources.module.css"
import altStyles from "../templates/WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const Input = ({ label, ...props }) => {
  const [isFocused, setFocus] = useState(false)
  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)

  return (
    <div
      className={cx(styles.input, {
        [styles.focused]: isFocused,
        [styles.valued]: props.value.trim() !== "",
        [styles.textarea]: props.type === "textarea",
      })}
    >
      <label>
        <span>{label}</span>
        {props.type === "textarea" ? (
          <textarea {...props} onFocus={onFocus} onBlur={onBlur} />
        ) : (
          <input type="text" {...props} onFocus={onFocus} onBlur={onBlur} />
        )}
      </label>
    </div>
  )
}

// eslint-disable-next-line
export const ResourcesPage = ({ data, resources, ...props }) => {

  return (
    <Layout {...props} {...data}>
      <div className={styles.preview}>
        <Container className={styles.previewcontainer}>
          <div>
            <Title className={altStyles.bannertitle}>{data.title}</Title>
            <p className={`${altStyles.title} ${altStyles.titlesmall} ${styles.title}`}>
              <span dangerouslySetInnerHTML={{ __html: data.bannerTitle || "" }} />
            </p>
            <div dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
          </div>
          {data.image ? (
            <div className={altStyles.image}>
              {data.image.extension === "svg" ? (
                <img alt={data.name} title={data.name} src={data.image.publicURL} loading="lazy" width="580" height="480"/>
              ) : data.image.childImageSharp ? (
                <Img
                  loading="lazy"
                  objectFit="contain"
                  alt={data.name}
                  title={data.name}
                  fluid={data.image.childImageSharp.fluid}
                />
              ) : null}
            </div>
          ) : null}
        </Container>
      </div>
      {resources.edges ? (
        <Container>
          <div className={styles.resourcesCards}>
            {(resources.edges || []).filter(Boolean).map(({ node }) => (
              <div className={styles.resourceCard} key={node.fields.slug}>
                <div className={styles.resourceCardImage}>
                  {node.frontmatter.image ? (
                    <Img
                      width="150"
                      height="100"
                      loading="lazy"
                      objectFit="scale-down"
                      alt={node.frontmatter.title}
                      title={node.frontmatter.title}
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                    />
                  ) : null}
                </div>
                {node.frontmatter.bannerTitle ? (
                  <span className={styles.resourceCardType}>{node.frontmatter.bannerTitle}</span>
                ) : null}
                {node.frontmatter.mainTitle ? (
                  <h2 className={styles.resourceCardTitle}>{node.frontmatter.mainTitle}</h2>
                ) : null}
                <p className={styles.resourceCardQuote}>
                  {node.frontmatter.quote}
                </p>
                {node.frontmatter.externalLink ? (
                  <a href={`${node.frontmatter.externalLink}`} target="_blank" rel="noreferrer" className={styles.resourceCardLink}>
                  Download Now
                </a>
                ) : (
                  <Link to={`/resources/${node.frontmatter.url}`} className={styles.resourceCardLink}>
                    Download Now
                  </Link>
                )}
              </div>
            ))}
          </div>
        </Container>
      ) : null}

      {data.form && data.portalId ? (
      <div className={altStyles.grayBackground} id="contact-form">
        <Container>
        <div className={altStyles.titleContainer}>
            <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: data.formTitle || ""}} /></TitleBlock>
          </div>
          <HubspotForm id={data.form} portalId={data.portalId} />
        </Container>
      </div>
    ) : null}
    </Layout>
  )
}

// eslint-disable-next-line
export const ResourcesPageWrapper = ({ data, ...props }) => (
  <ResourcesPage
    {...props}
    headerImage={data.headerImage}
    pinIcon={data.pinIcon}
    letterIcon={data.letterIcon}
    data={data.markdownRemark.frontmatter}
    resources={data.resources}

  />
)

export default ResourcesPageWrapper

export const pageQuery = graphql`
  query ResourcesQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "resources" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        bannerTitle
        description
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        form
        portalId
        formTitle
      }
    }
    resources: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "resource" } } }
      limit: 9
    )   {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              id
              url
              title
              mainTitle
              bannerTitle
              quote
              externalLink
              image {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
    }
  }
`
